<template>
  <v-container fill-height fluid style="height: 100%">
    <v-row class="text-center">
      <v-col cols="12">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
  <!--<v-container>
    <div class="app-container">
      <v-row class="text-center">
        <v-col cols="12">
          <v-btn @click="signIn" color="primary">Anmelden</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>-->
</template>

<script>
export default {
  methods: {
    signIn() {
      loginNew.auth();
    },
  },
  computed:{
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|MAPP/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    // Reagieren auf den Anmeldestatus
    // immediate = die Aktion wird immer mindestens 1 mal aufgerufen und nach jeder Änderung
    "$store.state.auth.isAuthenticating": {
      handler: function (isAuthenticating) {
        if (!isAuthenticating) {
          // Verlinkung auf die Startseite
             this.$router.push("/")
        }
      },
      immediate: true,
    },
  },
};
</script>
